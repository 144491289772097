import React from 'react';
import { useTheme } from 'shared/hooks/useTheme';

const SkipLink: React.FC = () => {
  const theme = useTheme();

  return (
    <section className="skip">
      <a href="#main">SKIP TO CONTENT</a>

      <style jsx>
        {`
          .skip {
            width: 100%;
            height: 0px;
          }

          .skip a {
            position: relative;
            height: 0;
            z-index: 1001;
            top: -5rem;
            left: ${theme.spacing.sm};
            padding: ${theme.spacing.md} ${theme.spacing.lg};
            background: ${theme.colors.primary};
            color: ${theme.colors.secondary};
            font-size: ${theme.fontSizes.md};
            border: 0;
            transition: all 0.2s;
          }

          .skip a:focus {
            top: ${theme.spacing.lg};
            transition: all 0.2s;
          }
        `}
      </style>
    </section>
  );
};

export default SkipLink;
