import React, { useRef, useEffect } from 'react';
import FocusLock from 'react-focus-lock';
import styled from 'styled-components';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { Transition, TransitionStatus } from 'react-transition-group';

interface Props {
  children: React.ReactNode;
  onClose: (event?: KeyboardEvent | React.SyntheticEvent) => void;
  isOpen: boolean;
}

const StyledDrawer = styled.div<{ duration: number; transitionState: TransitionStatus }>`
  ${({ duration, theme, transitionState }) => `  
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: start;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      transition: opacity ${duration}ms ease-in-out, z-index ${duration}ms ease-in-out;
      background-color: ${theme.components.modal.background.color};
      opacity: ${transitionState === 'entered' ? 0.8 : 0};
    }
  `}
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const DrawerContent = styled.div<{ duration: number; transitionState: TransitionStatus }>`
  ${({ theme, transitionState, duration }) => `
    position: absolute;
    transition: transform ${duration}ms ease-in-out, z-index ${duration}ms ease-in-out;
    right: 0;
    transform: ${transitionState === 'entered' ? 'none' : 'translateX(400px)'};
    width: 100%;
    max-width: 100%;
    height: 100vh;
    background-color: white;
    z-index: 1001;

    @media (min-width: ${theme.viewports.tablet}) {
      width: 400px;
    }
  `}
`;
const duration = 220;

export const Drawer: React.FC<Props> = ({ children, isOpen, onClose }) => {
  const myRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(myRef.current);
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isOpen]);

  return (
    <Transition in={isOpen} timeout={duration} mountOnEnter unmountOnExit>
      {(state) => {
        return (
          <StyledDrawer data-test="drawer" duration={duration} ref={myRef} onClick={onClose} transitionState={state}>
            <ContentWrapper>
              <FocusLock>
                <DrawerContent
                  duration={duration}
                  transitionState={state}
                  onClick={(e) => {
                    // stop event bubbling and closing the drawer
                    e.stopPropagation();
                  }}
                >
                  {children}
                </DrawerContent>
              </FocusLock>
            </ContentWrapper>
          </StyledDrawer>
        );
      }}
    </Transition>
  );
};
