import React from 'react';
import { useTheme } from 'shared/hooks/useTheme';
import { useBrand } from 'shared/hooks/useBrand';
import { useTranslation } from 'next-i18next';
import { Product, TranslationKey } from 'shared/constants';
import { Brand } from 'shared/types';
import { useBrandConfig } from 'shared/hooks/useBrandConfig';
import { VolvoNextSteps } from 'shared/components/NextSteps/Brand/VolvoNextSteps';
import { VolkswagenNextSteps } from 'shared/components/NextSteps/Brand/VolkswagenNextSteps';
import { useNextStepsJourney } from 'shared/hooks/useNextStepsJourney';

interface Props {
  showTitle?: boolean;
  showIcon?: boolean;
  logEventHandler: Function;
  product: Product;
}

const NextStepsBase: React.FC<Props> = ({ showTitle = false, showIcon = false, logEventHandler, product }) => {
  const theme = useTheme();
  const brand = useBrand();
  const brandConfig = useBrandConfig()[product];
  const shouldUseNewIcons = brandConfig?.shouldUseNewIcons;
  const { t: translate } = useTranslation([`${brand}.${TranslationKey.COMMON}`, `${brand}.${product}`]);

  const { steps: journeySteps, journeyType } = useNextStepsJourney(translate);

  return (
    <div className="next-steps" data-test="next-steps">
      <h1>Next Steps</h1>
      <div className="next-steps__items">
        {journeySteps.map((nextStep: string, index: number) => {
          const nextStepAsLowerCase = nextStep.toLowerCase();
          const utm = translate(`footer.nextSteps.${nextStepAsLowerCase}.query_params`, {
            ns: `${brand}.${product}`,
          });

          return (
            <div className="next-step" key={index}>
              {showTitle && <h3 className="next-step__title">{nextStep.replace(/_/g, ' ')}</h3>}
              <a
                href={translate(`footer${journeyType}.nextSteps.${nextStepAsLowerCase}.href`, {
                  utm,
                })}
                onClick={() => logEventHandler(`/clicked/${nextStepAsLowerCase}`)}
                rel="noopener noreferrer"
                target="_blank"
              >
                {showIcon && (
                  <div className="next-step__image">
                    <img
                      src={translate(`footer${journeyType}.nextSteps.${nextStepAsLowerCase}.icon`)}
                      alt={nextStepAsLowerCase.replace(/_/g, ' ')}
                    />
                  </div>
                )}
                <div className="next-step__link">
                  {translate(`footer${journeyType}.nextSteps.${nextStepAsLowerCase}.title`)}
                </div>
              </a>
            </div>
          );
        })}
      </div>
      <style jsx>
        {`
          .next-steps {
            display: flex;
            flex-flow: column wrap;
            align-items: center;
            font-family: '${theme.components.pageFooter.nextSteps.font.family}';
            background-color: ${theme.components.pageFooter.nextSteps.background.color};
            margin-top: 42px;
          }

          .next-steps h1 {
            flex-flow: row wrap;
            flex: 1;
            padding: ${theme.components.pageFooter.nextSteps.title.padding};
            color: ${theme.components.pageFooter.nextSteps.title.font.color};
            font-size: ${theme.components.pageFooter.nextSteps.title.font.size};
            font-family: ${theme.components.pageFooter.nextSteps.font.family};
          }

          .next-steps__items {
            display: flex;
            flex-direction: column;
          }

          .next-step {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin: 20px auto;
          }

          .next-step__title {
            align-self: center;
            color: ${theme.components.pageFooter.nextSteps.title.font.color};
            font-family: '${theme.components.pageFooter.nextSteps.title.font.family}';
          }

          .next-step__image {
            flex: 1;
            margin: ${theme.components.pageFooter.nextSteps.icon.margin};
            align-self: flex-start;
          }

          ${shouldUseNewIcons
            ? `
              .next-step__image img {
                width: 40px;
                height: 40px;
              }
            `
            : null}

          .next-step__link {
            line-height: ${theme.spacing.xxl};
            flex: 1;
            align-self: center;
            justify-content: center;
            color: ${theme.components.pageFooter.nextSteps.link.color};
            font-family: ${theme.components.pageFooter.nextSteps.link.font.family};
          }

          @media screen and (min-width: ${theme.viewports.tablet}) {
            .next-steps {
              margin-top: 72px;
            }

            .next-steps__items {
              flex-flow: row wrap;
            }

            .next-step {
              flex-flow: column;
              align-content: center;
              width: ${theme.components.pageFooter.linkWidth};
              margin-top: ${theme.components.pageFooter.nextSteps.margin.top};
              margin-right: ${theme.components.pageFooter.nextSteps.margin.right};
              margin-bottom: ${theme.components.pageFooter.nextSteps.margin.bottom};
              margin-left: ${theme.components.pageFooter.nextSteps.margin.left};
            }

            .next-step__title {
              line-height: ${theme.spacing.xxl};
              align-self: center;
              flex: 10;
            }

            .next-step__link {
              display: flex;
              align-items: flex-end;
              justify-items: center;
            }
          }
        `}
      </style>
    </div>
  );
};

interface VariantProps {
  logEventHandler: Function;
  product: Product;
}

const FordNextSteps: React.FC<VariantProps> = ({ logEventHandler, product }) => (
  <NextStepsBase showTitle showIcon logEventHandler={logEventHandler} product={product} />
);

const AudiNextSteps: React.FC<VariantProps> = ({ logEventHandler, product }) => (
  <NextStepsBase showIcon logEventHandler={logEventHandler} product={product} />
);

const MotabilityNextSteps: React.FC<VariantProps> = () => null;

const NextSteps = {
  [Brand.VOLVO]: (logEventHandler: Function, product: Product) => (
    <VolvoNextSteps logEventHandler={logEventHandler} product={product} brand={Brand.VOLVO} />
  ),
  [Brand.FORD]: (logEventHandler: Function, product: Product) => (
    <FordNextSteps logEventHandler={logEventHandler} product={product} />
  ),
  [Brand.WAYLANDS]: () => null,
  [Brand.AUDI]: (logEventHandler: Function, product: Product) => (
    <AudiNextSteps logEventHandler={logEventHandler} product={product} />
  ),
  [Brand.VOLKSWAGEN]: (logEventHandler: Function, product: Product) => (
    <VolkswagenNextSteps logEventHandler={logEventHandler} product={product} brand={Brand.VOLKSWAGEN} />
  ),
  [Brand.MOTABILITY]: (logEventHandler: Function, product: Product) => (
    <MotabilityNextSteps logEventHandler={logEventHandler} product={product} />
  ),
};

export { NextSteps as default, NextStepsBase };
