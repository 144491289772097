import React, { useState } from 'react';
import { useBrand } from 'shared/hooks/useBrand';
import { useTheme } from 'styled-components';
import { useTranslation } from 'next-i18next';
import { TranslationKey, Product, ValidProducts } from 'shared/constants';
import { useFeature } from 'shared/hooks/useFeature';
import { useAnalytics } from 'shared/hooks/useAnalytics';
import { FeatureToggles } from 'shared/constants/features';
import { KeyboardKey } from 'shared/types';
import { Drawer } from 'shared/components/Drawer';
import { NavMenu } from 'features/NavMenu/NavMenu';
import { NavMenuTrigger } from 'features/NavMenu/NavMenuTrigger';
import { useBrandConfig } from 'shared/hooks/useBrandConfig';

interface Props {
  showMenu: boolean;
  menuOnClickHandle: Function;
  openMobileMenu: boolean;
  pageId: Product;
}
const PageHeader: React.FC<Props> = ({ showMenu, menuOnClickHandle, openMobileMenu, pageId }): React.ReactElement => {
  const brand = useBrand();
  const theme = useTheme();
  const brandConfig = useBrandConfig()[pageId];
  const { t: translate } = useTranslation(`${brand}.${TranslationKey.COMMON}`);
  const toolFeatureToggles: { [key: string]: boolean } = {
    [Product.LANDING_PAGE]: useFeature(FeatureToggles.FEATURE_LANDING_PAGE, brand),
    [Product.EV_TREE]: useFeature(FeatureToggles.FEATURE_EV_DECISION_TREE, brand),
    [Product.COMPARATOR]: useFeature(FeatureToggles.FEATURE_COMPARATOR, brand),
    [Product.DETAILED_COMPARATOR]: useFeature(FeatureToggles.FEATURE_DETAILED_COMPARATOR, brand),
  };
  const shouldHideNavMenuOnPage = brandConfig?.features?.pageHeader?.shouldHideNavMenuOnPage;
  const isNavMenuFeatureEnabled = useFeature(FeatureToggles.FEATURE_SHOW_NEW_NAV_MENU, brand);
  const shouldShowNavMenu = !shouldHideNavMenuOnPage && isNavMenuFeatureEnabled;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const numberOfPagesAvailable: number = Object.values(toolFeatureToggles).filter((item) => item).length;
  const { logEvent } = useAnalytics(`/${brand}`);
  const displayMenu = showMenu && numberOfPagesAvailable > 1;

  const handleKeyPress = (ev: React.KeyboardEvent): void => {
    if (ev.code === KeyboardKey.ENTER) {
      window.location.href = translate('header.logo.href');
    }
  };

  const handleNewMenuClick = () => {
    setIsDrawerOpen(true);
  };

  const handleNewMenuKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setIsDrawerOpen(true);
    }
  };

  return (
    <>
      <div className="header" data-test="page-header">
        {!shouldShowNavMenu && displayMenu && (
          <button
            className="menu__button"
            data-test="hamburger-menu"
            onClick={() => menuOnClickHandle()}
            aria-label="open menu"
          >
            <span />
          </button>
        )}
        <span className="header__logo--container" tabIndex={0} onKeyDown={handleKeyPress}>
          <a
            className="header__logo"
            href={translate('header.logo.href')}
            target="_blank"
            rel="noopener noreferrer"
            onClick={async () => await logEvent(`/${pageId}/selected/brand-logo`)}
            tabIndex={-1}
          >
            {useFeature(FeatureToggles.FEATURE_PAGE_HEADER_LOGO, brand) && (
              <img
                className="header__img"
                alt={translate('header.logo.alt')}
                src={theme.layout.pageHeader.logo.imageSrc}
              />
            )}
          </a>
        </span>
        {shouldShowNavMenu && (
          <>
            <div className="nav-menu__trigger-wrapper">
              <NavMenuTrigger
                role="button"
                onClick={handleNewMenuClick}
                onKeyDown={handleNewMenuKeyDown}
                tabIndex={0}
                data-test="nav-menu-trigger"
              >
                {translate(`header.newMenu`)}
              </NavMenuTrigger>
            </div>

            <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
              <NavMenu onClose={() => setIsDrawerOpen(false)} />
            </Drawer>
          </>
        )}
        {!shouldShowNavMenu && displayMenu && (
          <nav className="header__items">
            {ValidProducts.map((item: string, index: number) => {
              return (
                toolFeatureToggles[item] &&
                numberOfPagesAvailable > 1 && (
                  <div className="item__wrapper" key={index}>
                    {pageId === item ? (
                      <a className="item__link item__link--selected">{translate(`header.${item}.title`)}</a>
                    ) : (
                      <a
                        className="item__link"
                        href={translate(`header.${item}.href`)}
                        onClick={async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
                          event.preventDefault();
                          await logEvent(`/${pageId}/selected/${item}`);
                          window.location.href = translate(`header.${item}.href`);
                        }}
                      >
                        {translate(`header.${item}.title`)}
                      </a>
                    )}
                  </div>
                )
              );
            })}
          </nav>
        )}
      </div>

      {!shouldShowNavMenu && displayMenu && (
        <nav
          className={`mobile-menu ${openMobileMenu ? 'mobile-menu--open' : 'mobile-menu--close'}`}
          data-test="mobile-menu"
        >
          <ul className="mobile-menu__items">
            {ValidProducts.map((item: string, index: number) => {
              return (
                toolFeatureToggles[item] &&
                numberOfPagesAvailable > 1 && (
                  <li className="mobile-item__wrapper" key={index}>
                    {pageId === item ? (
                      <a className="mobile-item__link mobile-item__link--selected">
                        {translate(`header.${item}.title`)}
                      </a>
                    ) : (
                      <a
                        className="mobile-item__link"
                        href={translate(`header.${item}.href`)}
                        onClick={async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
                          event.preventDefault();
                          await logEvent(`/${pageId}/selected/${item}`);
                          window.location.href = translate(`header.${item}.href`);
                        }}
                      >
                        {translate(`header.${item}.title`)}
                      </a>
                    )}
                  </li>
                )
              );
            })}
          </ul>
        </nav>
      )}
      <style jsx>
        {`
          .header {
            display: flex;
            height: ${theme.layout.pageHeader.height.mobile};
            background-color: ${theme.layout.pageHeader.backgroundColor};
            justify-content: ${theme.layout.pageHeader.logo.justifyContent};
            align-items: ${theme.layout.pageHeader.logo.alignItems};
          }

          .header__logo--container {
            display:  ${theme.layout.pageHeader.logo.container.display};
            grid-template-areas: ${`'${theme.layout.pageHeader.logo.container.gridTemplateAreas}'`};
            grid-template-columns: ${theme.layout.pageHeader.logo.container.gridTemplateColumns.mobile};
            grid-column-gap: calc(${theme.layout.pageHeader.logo.width.mobile}/4);
            width: ${theme.layout.pageHeader.logo.container.width};
            height: ${theme.layout.pageHeader.logo.container.height.mobile};
            align-items: center;
          }

          @media screen and (min-width: ${theme.viewports.desktop}) {
            .header__logo--container {
              grid-template-columns: ${theme.layout.pageHeader.logo.container.gridTemplateColumns.desktop};
              grid-column-gap: calc(${theme.layout.pageHeader.logo.width.desktop}/4);
              height: ${theme.layout.pageHeader.logo.container.height.desktop};
              align-items: center;
            }
          }

          .header__logo--container::before, .header__logo--container::after {
            content: "";
            height: ${theme.layout.pageHeader.logo.container.before.height};
            background-color: ${theme.layout.pageHeader.logo.container.before.backgroundColor};
          }

          .header__logo {
            display: flex;
            padding-left: ${theme.layout.pageHeader.logo.padding.left.mobile};
            grid-area: logo;
            height: 100%;
          }

          .header__img {
            width: ${theme.layout.pageHeader.logo.width.mobile};
            height: ${theme.layout.pageHeader.logo.height};
            margin: ${theme.layout.pageHeader.logo.margin};
          }

          .header__items {
            display: none;
          }

          .mobile-menu {
            position: absolute;
            top: 64px;
            left: 0;
            width: 100%;
            height: calc(100vh - 50px);
            background-color: ${theme.background.secondary};
            z-index: 100;
            overflow: scroll;
          }

          .mobile-menu__items {
            list-style: none;
            padding: unset;
            margin: unset;
          }

          .mobile-item__wrapper {
            padding: 1rem 1rem;
            font-size: ${theme.fontSizes.xxl};
          }

          .mobile-item__link--selected {
            border-bottom: 2px solid ${theme.colors.tertiary};
            padding-bottom: 4px;
          }

          .mobile-menu--close {
            display: none;
          }

          .mobile-menu--open {
            display: flex;
            flex-direction: column;
            justify-items: center;
          }

          .menu__button {
            width: 30px;
            cursor: pointer;
            background: none;
            border: none;
            padding: 0px;
            margin: 10px;
            min-height: 30px;
          }

          .menu__button__item {
            padding: 2rem;
            margin: 0 1rem;
            font-size: ${theme.fontSizes.xl};
          }

          .menu__button::after,
          .menu__button::before {
            content: '';
          }

          .menu__button::before,
          .menu__button::after,
          .menu__button span {
            background: black;
            display: block;
            height: 3px;
            margin: 5px 0 0;
            transition: all 0.2s ease-in-out;
          }

          .menu__button::before {
            margin: 0;
          }

          .menu__button.is-active::before {
            transform: translateY(8px) rotate(135deg);
          }

          .menu__button.is-active::after {
            transform: translateY(-8px) rotate(-135deg);
          }

          .menu__button.is-active span {
            background: transparent;
          }

          .menu__button::focus,
          .menu__button::focus-visible {
            outline: thin dotted ${theme.colors.tertiary};
          }

          .links--mobile {
            position: absolute;
            top: -0.5rem;
            left: 0;
            width: 30px;
            cursor: pointer;
            background: none;
            border: none;
            padding: 0;
            min-height: 30px;
          }

          .links--desktop {
            display: none;
          }

          @media screen and (min-width: ${theme.viewports.tablet}) {
            .mobile-menu,
            .menu__button {
              display: none;
            }

            .header {
              align-items: ${theme.layout.pageHeader.logo.alignItems};
              justify-content: ${theme.layout.pageHeader.logo.justifyContent};
              flex-direction: row;
              flex-wrap: nowrap;
            }

            .header__items {
              display: flex;
              flex-grow: 1;
              justify-content: flex-end;
              margin-right: 10px;
            }

            .item__wrapper::after {
              content: ' |';
            }

            .item__wrapper:last-child::after {
              content: '';
            }

            .item__link {
              position: relative;
              margin: 2px 4px;
            }

            .item__link::after,
            .item__link--selected::after {
              position: absolute;
              left: 0;
              bottom: 0;
              content: '';
              border-bottom: 1px solid #000;
              width: 0;
              transition: all 0.25s linear;
              margin-bottom: -4px;
            }

            .item__link:hover::after {
              width: 100%;
            }

            .item__link--selected::after {
              width: 100%;
            }

            .header__logo {
              padding-left: ${theme.layout.pageHeader.logo.padding.left.mobile};
            }

            .header__img {
              width: ${theme.layout.pageHeader.logo.width.tablet};
            }
          }

          @media screen and (min-width: ${theme.viewports.desktop}) {
            .header {
              justify-content: flex-start;
              height: ${theme.layout.pageHeader.height.desktop};
              align-items: ${theme.layout.pageHeader.logo.alignItems};
            }

            .header__logo {
              padding-left: ${theme.layout.pageHeader.logo.padding.left.desktop};
            }

            .header__img {
              width: ${theme.layout.pageHeader.logo.width.desktop};
            }
          }
          .nav-menu__trigger-wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
          }
          }
        `}
      </style>
    </>
  );
};

export default PageHeader;
