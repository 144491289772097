import React from 'react';
import { Loader as Loading } from 'shared/components/icons';
import styled, { css } from 'styled-components';
import { BaseTheme } from 'shared/types';

interface Props extends React.HTMLProps<HTMLButtonElement | HTMLAnchorElement> {
  text: string | React.ReactNode;
  iconSource?: string;
  appendIcon?: boolean;
  isLoading?: boolean;
  isLink?: boolean;
  href?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  buttonSize?: ButtonSizes;
  prependIcon?: boolean;
  buttonStyleType?: ButtonStyleTypes;
}

export enum ButtonSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ButtonStyleTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

const getSizeRelatedStyles = (theme: BaseTheme, size?: ButtonSizes) => {
  const { buttons } = theme;
  switch (size) {
    case ButtonSizes.SMALL:
    case ButtonSizes.MEDIUM:
    case ButtonSizes.LARGE:
      return `
        min-width: ${buttons.globals.variants[size].width};
        height: ${buttons.globals.variants[size].height};
        padding: ${buttons.globals.variants[size].padding};
      `;
    default:
      return '';
  }
};

const prependAppendStyles = css`
  ${({ theme }) => {
    const { buttons } = theme;
    return `
      position: relative;
      top: 0;
      vertical-align: middle;
      transition: ${buttons.globals.transition};
    `;
  }}
`;

const getIconStyles = (theme: BaseTheme, prependIcon?: boolean, appendIcon?: boolean) => {
  const { buttons } = theme;
  if (prependIcon || appendIcon) {
    return `
      ${prependAppendStyles};
      & svg {
        ${appendIcon ? 'margin-left: 4px' : ''};
        ${prependIcon ? 'margin-right: 4px' : ''};
        transition: ${buttons.globals.transition};
      }
    `;
  }
  return '';
};

const getPrimaryStyles = (theme) => {
  const { buttons } = theme;
  return `
      border-width: ${buttons.primary.border.width};
      border-style: ${buttons.primary.border.style};
      border-color: ${buttons.primary.border.color};
      border-radius: ${buttons.primary.border.radius};
      background-color: ${buttons.primary.backgroundColor};
      color: ${buttons.primary.font.color};

      &:hover {
        border-color: ${buttons.primary.state.hover.borderColor};
        background-color: ${buttons.primary.state.hover.backgroundColor};
        color: ${buttons.primary.state.hover.fontColor};

        & svg {
          fill: ${buttons.primary.font.color};
        }
      }

      &:active {
        border-color: ${buttons.primary.state.hover.borderColor};
        background-color: ${buttons.primary.state.hover.backgroundColor};
        transform: ${buttons.primary.state.active.transform};
      }

      &:disabled {
        color: ${buttons.primary.state.disabled.color};
        border-color: ${buttons.primary.state.disabled.borderColor};
        background-color: ${buttons.primary.state.disabled.backgroundColor};
        transform: unset;
      }

      & svg {
        fill: ${buttons.primary.font.color};
      }
    `;
};

const getSecondaryStyles = (theme) => {
  const { buttons } = theme;
  return `
    border-width: ${buttons.secondary.border.width};
    border-style: ${buttons.secondary.border.style};
    border-color: ${buttons.secondary.border.color};
    border-radius: ${buttons.secondary.border.radius};
    background-color: ${buttons.secondary.backgroundColor};
    color: ${buttons.secondary.font.color};

    & svg {
      fill: ${buttons.secondary.font.color};
    }

    &:hover {
      border-color: ${buttons.secondary.state.hover.borderColor};
      background-color: ${buttons.secondary.state.hover.backgroundColor};
      color: ${buttons.secondary.state.hover.fontColor};

      & svg {
        fill: ${buttons.secondary.state.hover.fontColor};
      }
    }

    &:active {
      border-color: ${buttons.secondary.state.hover.borderColor};
      background-color: ${buttons.secondary.state.hover.backgroundColor};
      transform: ${buttons.secondary.state.active.transform};
      color: ${buttons.secondary.state.active.font.color};
    }

    &:disabled {
      color: ${buttons.secondary.state.disabled.color};
      border-color: ${buttons.secondary.state.disabled.borderColor};
      background-color: ${buttons.secondary.state.disabled.backgroundColor};
      transform: unset;
    }

    &:focus {
      background: ${buttons.secondary.state.focussed.backgroundColor};
      color: ${buttons.secondary.state.focussed.fontColor};
    }
  `;
};

const getTertiaryStyles = (theme) => {
  const { alpha, buttons, colors } = theme;
  return `
    border-width: ${buttons.tertiary.border.width};
    border-style: ${buttons.tertiary.border.style};
    border-color: ${buttons.tertiary.border.color};
    border-radius: ${buttons.tertiary.border.radius};
    background-color: ${buttons.tertiary.backgroundColor};
    color: ${buttons.tertiary.font.color};
    text-align: left;
    padding: unset;
    font-weight: ${buttons.tertiary.font.weight};
    text-decoration: ${buttons.tertiary.font.decoration};
    text-decoration-thickness: ${buttons.tertiary.font.decorationThickness};

    & svg {
      fill: ${buttons.tertiary.font.color};
      stroke: ${buttons.tertiary.font.color};
    }

    &:hover {
      border-color: ${buttons.tertiary.state.hover.borderColor};
      background-color: ${buttons.tertiary.state.hover.backgroundColor};
      color: ${buttons.tertiary.state.hover.fontColor};

      & svg {
        fill: ${buttons.tertiary.state.hover.fontColor};
        stroke: ${buttons.tertiary.state.hover.fontColor};
        fill: ${colors.senary};
      }
    }

    &:active {
      color: ${buttons.tertiary.state.active.font.color};
      border-color: ${buttons.tertiary.state.hover.borderColor};
      background-color: ${buttons.tertiary.state.hover.backgroundColor};
      transform: ${buttons.tertiary.state.active.transform};

      & svg {
        stroke: ${colors.senary}${alpha.primary};

        & path {
          fill: ${colors.senary}${alpha.primary};
        }
      }
    }

    &:focus {
      background: ${buttons.tertiary.state.focussed.backgroundColor};
      color: ${buttons.tertiary.state.focussed.fontColor};
    }

    &:disabled {
      color: ${buttons.tertiary.state.disabled.color};
      border-color: ${buttons.tertiary.state.disabled.borderColor};
      background-color: ${buttons.tertiary.state.disabled.backgroundColor};

      & svg {
        stroke: ${buttons.tertiary.state.disabled.color};

        & path {
          fill: ${buttons.tertiary.state.disabled.color};
        }
      }
    }
  `;
};

const StyledButton = styled.button<{
  buttonStyleType: ButtonStyleTypes | undefined;
  size?: ButtonSizes;
  prependIcon?: boolean;
  appendIcon?: boolean;
  isLoading?: boolean;
}>`
  ${({ appendIcon, buttonStyleType, isLoading, prependIcon, size, theme }) => {
    const { buttons } = theme;
    return `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-width: ${buttons.globals.border.width};
    border-style: ${buttons.globals.border.style};
    border-color: ${buttons.globals.border.color};
    border-radius: ${buttons.globals.border.radius};
    font: ${buttons.globals.font};
    text-transform: ${buttons.globals.transform};
    color: ${buttons.globals.color};
    transition: ${buttons.globals.transition};

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:focus-visible {
      outline-color: ${buttons.primary.state.focussed.outline.color};
      outline-style: ${buttons.primary.state.focussed.outline.style};
      outline-width: ${buttons.primary.state.focussed.outline.width};
      outline-offset: ${buttons.primary.state.focussed.outline.offset};
    }

    &:focus {
      background: ${buttons.primary.state.focussed.backgroundColor};
      color: ${buttons.primary.state.focussed.fontColor};
    }
    ${getSizeRelatedStyles(theme, size)};
    ${getIconStyles(theme, prependIcon, appendIcon)};
    ${
      isLoading
        ? `
      position: relative;
    `
        : ''
    };
    ${buttonStyleType === ButtonStyleTypes.PRIMARY ? getPrimaryStyles(theme) : ''};
    ${buttonStyleType === ButtonStyleTypes.SECONDARY ? getSecondaryStyles(theme) : ''};
    ${buttonStyleType === ButtonStyleTypes.TERTIARY ? getTertiaryStyles(theme) : ''};
  `;
  }}
`;

const LoadingWrapper = styled.span<{ buttonStyleType: ButtonStyleTypes | undefined }>`
  ${({ theme, buttonStyleType }) => {
    const { colors } = theme;
    return `
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      border-radius: inherit;

      & svg {
        height: 100%;
        ${
          buttonStyleType === ButtonStyleTypes.PRIMARY
            ? `
          fill: ${colors.secondary};
        `
            : ''
        };
        ${
          buttonStyleType === ButtonStyleTypes.SECONDARY
            ? `
            fill: ${colors.primary};
        `
            : ''
        };
      }
    `;
  }}
`;

const Button: React.FC<Props> = ({
  text,
  iconSource,
  appendIcon = false,
  prependIcon = false,
  isLoading = false,
  isLink = false,
  href = '',
  buttonSize = ButtonSizes.LARGE,
  type = 'button',
  children,
  buttonStyleType,
  ...props
}) => {
  return (
    <>
      {isLink && (
        <StyledButton
          as="a"
          href={href}
          size={buttonSize}
          prependIcon={prependIcon}
          appendIcon={appendIcon}
          buttonStyleType={buttonStyleType}
          {...(props as Record<string, number>)}
        >
          {typeof children !== undefined && prependIcon && children}
          <span>{text}</span>
          {typeof children !== undefined && appendIcon && children}
        </StyledButton>
      )}

      {!isLink && (
        <StyledButton
          type={type}
          size={buttonSize}
          prependIcon={prependIcon}
          appendIcon={appendIcon}
          isLoading={isLoading}
          disabled={isLoading}
          buttonStyleType={buttonStyleType}
          {...(props as Record<string, unknown>)}
        >
          {isLoading && (
            <LoadingWrapper buttonStyleType={buttonStyleType}>
              <Loading />
            </LoadingWrapper>
          )}

          {typeof children !== undefined && prependIcon && children}

          {text}

          {typeof children !== undefined && appendIcon && children}
        </StyledButton>
      )}
    </>
  );
};

const PrimaryButton: React.FC<Props> = ({
  text,
  iconSource,
  appendIcon,
  isLoading = false,
  type,
  buttonSize = ButtonSizes.LARGE,
  ...props
}) => (
  <Button
    text={text}
    iconSource={iconSource}
    isLoading={isLoading}
    appendIcon={appendIcon}
    type={type}
    buttonSize={buttonSize}
    buttonStyleType={ButtonStyleTypes.PRIMARY}
    {...props}
  />
);

const SecondaryButton: React.FC<Props> = ({
  text,
  iconSource,
  appendIcon,
  isLoading = false,
  buttonSize = ButtonSizes.LARGE,
  ...props
}) => (
  <Button
    text={text}
    iconSource={iconSource}
    isLoading={isLoading}
    appendIcon={appendIcon}
    buttonSize={buttonSize}
    buttonStyleType={ButtonStyleTypes.SECONDARY}
    {...props}
  />
);

const TertiaryButton: React.FC<Props> = ({
  text,
  iconSource,
  isLoading = false,
  isLink = false,
  href = '',
  prependIcon = false,
  appendIcon = false,
  ...props
}) => (
  <Button
    prependIcon={prependIcon}
    appendIcon={appendIcon}
    text={text}
    isLoading={isLoading}
    isLink={isLink}
    href={href}
    buttonStyleType={ButtonStyleTypes.TERTIARY}
    {...props}
  />
);

const LinkAsPrimaryButton: React.FC<Props> = ({ text, href, iconSource, appendIcon, ...props }) => (
  <Button
    text={text}
    href={href}
    isLink
    iconSource={iconSource}
    appendIcon={appendIcon}
    buttonStyleType={ButtonStyleTypes.PRIMARY}
    {...props}
  />
);

const LinkAsSecondaryButton: React.FC<Props> = ({ text, href, buttonSize, ...props }) => (
  <Button
    text={text}
    href={href}
    isLink
    buttonSize={buttonSize}
    buttonStyleType={ButtonStyleTypes.SECONDARY}
    {...props}
  />
);

export {
  Button as default,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  LinkAsPrimaryButton,
  LinkAsSecondaryButton,
};
