import { useEffect, useState } from 'react';
import { JourneyType } from 'shared/types';
import { useJourney } from 'shared/hooks/useJourney';

export const useNextStepsJourney = (translate: Function) => {
  const [steps, setSteps] = useState<string[]>([]);
  const { journeyData } = useJourney();

  useEffect(() => {
    const nextStepsJourney =
      journeyData === JourneyType.JOURNEY_PROVIDER_EMPTY
        ? translate('footer.nextSteps')
        : translate(`footer.journey.${journeyData}.nextSteps`);
    setSteps(nextStepsJourney.split(','));
  }, [journeyData, translate]);

  const journeyType = journeyData === JourneyType.JOURNEY_PROVIDER_EMPTY ? '' : `.journey.${journeyData}`;

  return { steps, journeyType };
};
