import React, { ReactNode, useState, useEffect } from 'react';
import { EnableMenuByBrand, GoogleIconsLibrary, Product, ProductsWithFullMenu } from 'shared/constants';
import { Brand, JourneyTypeParam, AnalyticsEventType } from 'shared/types';
import { useAnalytics } from 'shared/hooks/useAnalytics';
import Head from 'next/head';
import SkipLink from 'shared/components/SkipLink';
import PageHeader from 'shared/components/PageHeader';
import PageFooter from 'shared/components/PageFooter';
import Loader from 'shared/components/Loader';
import { useFeature } from 'shared/hooks/useFeature';
import { FeatureToggles } from 'shared/constants/features';
import { useTheme } from 'shared/hooks/useTheme';
import { NextSteps } from './NextSteps';

interface Props {
  pageID: Product;
  title: string;
  description: string;
  brand: Brand;
  hasError?: boolean;
  error?: unknown;
  isLoading?: boolean;
  children?: ReactNode;
}

const Page: React.FC<Props> = ({
  pageID,
  title,
  description,
  brand,
  hasError = false,
  error = false,
  isLoading = false,
  children,
}) => {
  const [mobileMenuToggle, setMobileMenuToggle] = useState(false);
  const [pageWidth, setPageWidth] = useState(0);
  const { logEvent } = useAnalytics(`/${brand}/${pageID}`);
  const theme = useTheme();

  const handleHamburgerClick = async (): Promise<void> => {
    await logEvent(`/hamburger/click/${mobileMenuToggle ? 'close' : 'open'}`);
    setMobileMenuToggle(!mobileMenuToggle);

    document.body.classList.toggle('mobile-menu-is-open');

    window.addEventListener(
      'resize',
      (): void => {
        if (window.innerWidth > pageWidth) {
          document.body.classList.remove('mobile-menu-is-open');
          setPageWidth(window.innerWidth);
          setMobileMenuToggle(mobileMenuToggle);
        }
      },
      true
    );
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    const searchParams = new URLSearchParams(window.location.search);

    logEvent(
      `/${brand}/${pageID}${
        pageID === Product.COMPARATOR || pageID === Product.DETAILED_COMPARATOR
          ? `/${searchParams.get('journeyType') || JourneyTypeParam.PRIVATE}`
          : ''
      }`,
      AnalyticsEventType.NAVIGATION
    );
    setPageWidth(window.innerWidth);
  }, []);

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=2.0" />
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {GoogleIconsLibrary[brand] ? (
          <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
        ) : null}

        {Object.keys(theme.favicons).map((size: string, index: number) => (
          <link
            key={index}
            rel="icon"
            sizes={`${size}x${size}`}
            href={`/assets/${brand}/common/${theme.favicons[size]}`}
            type={`image/${theme.favicons[size].split('.')[theme.favicons[size].split('.').length - 1]}`}
          />
        ))}
      </Head>
      <SkipLink />

      {useFeature(FeatureToggles.FEATURE_PAGE_HEADER, brand) && (
        <PageHeader
          showMenu={ProductsWithFullMenu.includes(pageID) && EnableMenuByBrand.includes(brand)}
          menuOnClickHandle={handleHamburgerClick}
          openMobileMenu={mobileMenuToggle}
          pageId={pageID}
        />
      )}

      {!isLoading && (hasError || error) && error}
      <main id="main">
        {error === undefined && !hasError && isLoading && <Loader />}
        {!hasError && children}
      </main>

      {useFeature(FeatureToggles.FEATURE_PAGE_FOOTER, brand) && (
        <>
          {ProductsWithFullMenu.includes(pageID) && NextSteps[brand](logEvent, pageID)}
          <PageFooter pageId={pageID} logEventHandler={logEvent} />
        </>
      )}
    </>
  );
};

export default Page;
