import React from 'react';
import styled from 'styled-components';
import { useBrand } from 'shared/hooks/useBrand';
import { KeyboardKey } from 'shared/types';
import { useTranslation } from 'next-i18next';
import { TranslationKey } from 'shared/constants';

const NavMenuLinkListItem = styled.li`
  ${({ theme }) => `
    display: flex;
    padding: ${theme.components.navMenu.link.listItem.padding};
    cursor: pointer;
    height: ${theme.components.navMenu.link.listItem.height};

    &:hover {
      background: ${theme.components.navMenu.link.listItem.hover.backgroundColor};
    }
  `}
`;

const NavMenuNavLink = styled.a`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    font: ${theme.components.navMenu.link.font};
  `}
`;

const Icon = styled.svg<{ src: string }>`
  ${({ theme, src }) => `
    width: ${theme.components.navMenu.link.icon.width};
    height: ${theme.components.navMenu.link.icon.height};
    background-color: ${theme.components.navMenu.link.icon.backgroundColor};
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    mask-image: url(${src});
  `}
`;

interface Props {
  link?: string;
  text: string;
  onClick?: () => void;
}

export const NavMenuLink: React.FC<Props> = ({ link, text, onClick }) => {
  const brand = useBrand();
  const { t: translate } = useTranslation(`${brand}.${TranslationKey.COMMON}`);
  const handleKeyPress = async (ev: React.KeyboardEvent): Promise<void> => {
    if (ev.code === KeyboardKey.ENTER) {
      onClick?.();
    }
  };

  return (
    <NavMenuLinkListItem data-test="nav-menu-link" onClick={onClick} onKeyDown={handleKeyPress}>
      <NavMenuNavLink href={link} rel="noopener noreferrer" target="_blank" tabIndex={0}>
        {translate(text)} {onClick ? <Icon src={`/assets/${brand}/Icons/large_chevron_right.svg`} /> : null}
      </NavMenuNavLink>
    </NavMenuLinkListItem>
  );
};
