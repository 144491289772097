import React from 'react';
import { Loader as Loading } from 'shared/components/icons';
import { useTheme } from 'shared/hooks/useTheme';

interface Props {
  text?: string;
  largeSize?: boolean;
}

const Loader: React.FC<Props> = ({ text = 'Loading...', largeSize = false }) => {
  const theme = useTheme();

  return (
    <div className="loader">
      <div className="loader__inner">
        <div className={largeSize ? 'loader__icon loader__icon--large' : 'loader__icon'}>
          <Loading />
        </div>

        {text !== '' && <p className="loader__content">{text}</p>}
      </div>

      <style jsx>
        {`
          .loader {
            display: flex;
            justify-content: center;
            align-items: start;
            text-align: center;
          }

          .loader__inner {
            position: relative;
            background: ${theme.colors.secondary};
            padding: 1rem;
          }

          .loader__content {
            font-size: ${theme.components.loader.text.fontSize};
            margin-top: 27px;
          }

          :global(.loader__icon > svg > path) {
            fill: ${theme.components.loader.icon.fill};
          }

          :global(.loader__icon--large > svg) {
            width: 90px;
            height: 90px;
          }
        `}
      </style>
    </div>
  );
};

export default Loader;
