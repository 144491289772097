import { FeatureToggles } from 'shared/constants/features';
import { useFeature } from 'shared/hooks/useFeature';
import { AnalyticsEventType } from 'shared/types';
import { logger } from 'services/utils/logger';
import { useRouter } from 'next/router';

interface UseAnalytics {
  logEvent: (path: string, type?: AnalyticsEventType) => Promise<void>;
}

const useAnalytics = (prefix = ''): UseAnalytics => {
  const { asPath } = useRouter();
  const pathPrefix = prefix || asPath.split('?')[0];
  const canPersist = useFeature(FeatureToggles.FEATURE_ANALYTICS_PERSIST);
  const logEvent = async (path: string, type = AnalyticsEventType.EVENT): Promise<void> => {
    const result = type === AnalyticsEventType.EVENT ? `${pathPrefix}${path}` : path;
    try {
      await fetch('/api/analytics', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          path: result,
          type,
          canPersist,
        }),
      });
    } catch (error) {
      logger.error(`[ANALYTICS] Unable to log event: ${error.message}`);
    }
  };

  return { logEvent };
};

export { useAnalytics };
