import styled from 'styled-components';

export const NavMenuTrigger = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: ${theme.layout.pageHeader.logo.padding.left.mobile};
    height: 38px;
    border-bottom: 1px solid transparent;
    font: ${theme.components.navMenu.trigger.font.mobile};

    &:hover {
        border-bottom: 1px solid #161618;
    }

    @media screen and (min-width: ${theme.viewports.desktop}) {
        margin-right: ${theme.layout.pageHeader.logo.padding.left.desktop};
    }
  `}
`;
