import styled from 'styled-components';

export const CloseIcon = styled.svg<{ src: string }>`
  ${({ src, theme }) =>
    `   
        width: ${theme.components.navMenu.closeButton.icon.width};
        height: ${theme.components.navMenu.closeButton.icon.height};
        background-color: ${theme.components.navMenu.closeButton.icon.backgroundColor};
        mask-repeat: no-repeat;
        mask-size: 100% 100%;
        mask-image: url(${src});
    `}
`;
