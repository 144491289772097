import React from 'react';
import { useTranslation } from 'next-i18next';
import { Container } from 'shared/components/Container';
import styled from 'styled-components';
import { Product, TranslationKey } from 'shared/constants';
import { Brand } from 'shared/types';
import { Step } from 'shared/components/NextSteps/Brand/VolvoNextSteps/Step';
import { useNextStepsJourney } from 'shared/hooks/useNextStepsJourney';

const NextStepsContent = styled.div`
  ${({ theme }) => `
    color: ${theme.components.nextStepsRedesign.color};
    background-color: ${theme.components.nextStepsRedesign.backgroundColor};
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: ${theme.components.nextStepsRedesign.marginMobile};
    padding: ${theme.components.nextStepsRedesign.paddingMobile};

    @media screen and (min-width: ${theme.viewports.desktop}) {
      margin: ${theme.components.nextStepsRedesign.marginDesktop};
      padding: ${theme.components.nextStepsRedesign.paddingDesktop};
    }
  `}
`;

const NextStepsTitle = styled.h2`
  ${({ theme }) => `
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: ${theme.components.nextStepsRedesign.title.marginMobile};
    font: ${theme.components.nextStepsRedesign.title.fontMobile};

    @media screen and (min-width: ${theme.viewports.desktop}) {
      justify-content: center;
      margin: ${theme.components.nextStepsRedesign.title.marginDesktop};
      font: ${theme.components.nextStepsRedesign.title.fontDesktop};
    }
  `}
`;

const NextStepsItemsWrapper = styled.div<{ stepQuantity: number }>`
  ${({ stepQuantity, theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;

    @media screen and (min-width: ${theme.viewports.desktop}) {
      display: grid;
      grid-template-columns: repeat(${stepQuantity}, 1fr);
      flex-direction: row;
      gap: 50px;
    }
  `}
`;

interface Props {
  brand: Brand;
  product: Product;
  logEventHandler: Function;
}

export const VolvoNextSteps: React.FC<Props> = ({ brand, logEventHandler, product }) => {
  const { t: translate } = useTranslation([`${brand}.${TranslationKey.COMMON}`, `${brand}.${product}`]);
  const { steps: journeySteps, journeyType } = useNextStepsJourney(translate);

  const steps = journeySteps.map((step) => {
    const nextStepAsLowerCase = step.toLowerCase();
    const utm = translate(`footer.nextSteps.${nextStepAsLowerCase}.query_params`, {
      ns: `${brand}.${product}`,
    });
    const title = translate(`footer${journeyType}.nextSteps.${nextStepAsLowerCase}.title`);
    const description = translate(`footer${journeyType}.nextSteps.${nextStepAsLowerCase}.description`);
    const ctaText = translate(`footer${journeyType}.nextSteps.${nextStepAsLowerCase}.ctaText`);
    const ctaLink = translate(`footer${journeyType}.nextSteps.${nextStepAsLowerCase}.href`, {
      utm,
    });

    const handleClick = () => logEventHandler(`/clicked/${nextStepAsLowerCase}`);
    return { title, description, ctaText, ctaLink, handleClick };
  });

  return (
    <Container>
      <NextStepsContent>
        <NextStepsTitle>{translate('footer.nextSteps.title')}</NextStepsTitle>
        <NextStepsItemsWrapper stepQuantity={steps.length}>
          {steps.map(({ title, description, ctaText, ctaLink, handleClick }, index) => {
            return (
              <Step
                handleClick={handleClick}
                title={title}
                description={description}
                ctaLink={ctaLink}
                ctaText={ctaText}
                key={index}
              />
            );
          })}
        </NextStepsItemsWrapper>
      </NextStepsContent>
    </Container>
  );
};

export default VolvoNextSteps;
