import { MenuLink } from 'features/NavMenu/NavMenu';
import { v4 } from 'uuid';

interface FlattenedLink {
  id: string;
  parentId: string;
  title: string;
  links: MenuLink[];
  url: string;
  openInCurrentTab?: boolean | undefined;
}

interface FlattenedObject {
  [key: string]: FlattenedLink;
}

export type FlattenedLinks = {
  [key: string]: FlattenedLink;
};

const flatten = (previousObject: FlattenedObject, currentObject: MenuLink | FlattenedLink): FlattenedObject => {
  const newEntry = { ...currentObject } as FlattenedLink;

  newEntry.id = newEntry.id ?? v4();

  const newObject = { ...previousObject, [newEntry.id]: { ...newEntry } };

  if (Array.isArray(currentObject.links)) {
    const linksWithIds = currentObject.links.map((link) => ({
      ...link,
      id: v4(),
      parentId: newEntry.id,
    }));

    newObject[newEntry.id].links = linksWithIds.map((link) => {
      return link.id;
    });
    return linksWithIds.reduce(flatten, newObject);
  }
  return newObject;
};

export const flattenNavMenu = (menu: MenuLink[]): FlattenedLinks => {
  return menu.reduce(flatten, {});
};
