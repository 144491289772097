import React from 'react';
import styled from 'styled-components';
import { TertiaryButton } from 'shared/components/Button';
import { Trans } from 'next-i18next';
import { TranslationKey } from 'shared/constants';
import { Brand } from 'shared/types';
import ExternalLink from 'shared/components/icons/external_link.svg';
import { fonts } from 'shared/theme/volkswagen';

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StepDescription = styled.p`
  ${({ theme }) => `
    font: ${theme.components.nextStepsRedesign.stepDescription.font};
    margin-bottom: 25px;
    max-width: 341px;

    @media screen and (max-width: ${theme.viewports.tablet}) {
      max-width: 387px;
    }
  `}
`;

const StrongStepDescription = styled.span`
  font: ${fonts.desktop.h3Bold};
`;

const NextStepImage = styled.img`
  margin-bottom: 20px;
  height: auto;
  width: 100%;
`;

const images = {
  contact_retailer: `/assets/${Brand.VOLKSWAGEN}/components/next-steps/contact-us.jpg`,
  contact_company_car: `/assets/${Brand.VOLKSWAGEN}/components/next-steps/contact-us.jpg`,
  request_a_demo: `/assets/${Brand.VOLKSWAGEN}/components/next-steps/request-a-demo.jpg`,
  contact_fleet: `/assets/${Brand.VOLKSWAGEN}/components/next-steps/contact_fleet.jpg`,
  test_drive: `/assets/${Brand.VOLKSWAGEN}/components/next-steps/test-drive.jpg`,
  view_offers: `/assets/${Brand.VOLKSWAGEN}/components/next-steps/view_offers.jpg`,
};

const StepCTA = styled(TertiaryButton).attrs(() => ({
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  ${({ theme }) => `
    &&& {
      justify-content: flex-start;

      &:hover {
        & span {
          background-color: ${theme.components.nextStepsRedesign.link.hoverColor};
        }
      }

      &:focus,
      &:focus-visible {
        & span {
          outline: 2px solid #48B9C7;
          outline-offset: 4px;
        }
      }

      @media screen and (min-width: ${theme.viewports.desktop}) {
        padding: 0;
      }
    }
  `}
`;

interface Props {
  id: string;
  ctaLink: string;
  ctaText: string;
  description: string;
  handleClick: () => void;
  brand: Brand;
}

export const Step: React.FC<Props> = ({ id, ctaLink, ctaText, description, handleClick, brand }) => {
  return (
    <StepWrapper>
      <NextStepImage src={images[id]} />
      <TitleAndDescriptionWrapper>
        <StepDescription>
          <Trans
            i18nKey={description}
            ns={`${brand}.${TranslationKey.COMMON}`}
            components={{ strong: <StrongStepDescription /> }}
          />
        </StepDescription>
      </TitleAndDescriptionWrapper>
      <StepCTA text={ctaText} href={ctaLink} isLink onClick={handleClick} appendIcon>
        <ExternalLink />
      </StepCTA>
    </StepWrapper>
  );
};
