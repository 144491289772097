import React from 'react';
import styled from 'styled-components';
import { SecondaryButton } from 'shared/components/Button';

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StepTitle = styled.p`
  ${({ theme }) => `
    font: ${theme.components.nextStepsRedesign.stepTitle.font};
    margin: ${theme.components.nextStepsRedesign.stepTitle.margin};
  `}
`;

const StepDescription = styled.p`
  ${({ theme }) => `
    font: ${theme.components.nextStepsRedesign.stepDescription.font};
    margin: ${theme.components.nextStepsRedesign.stepDescription.margin};
  `}
`;

const StepCTA = styled(SecondaryButton).attrs(() => ({
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  ${({ theme }) => `
    padding: ${theme.components.nextStepsRedesign.stepCTA.paddingMobile};
    width: fit-content;

    @media screen and (min-width: ${theme.viewports.desktop}) {
      padding: ${theme.components.nextStepsRedesign.stepCTA.paddingDesktop};
    }
  `}
`;

interface Props {
  ctaLink: string;
  ctaText: string;
  description: string;
  title: string;
  handleClick: () => void;
}

export const Step: React.FC<Props> = ({ ctaLink, ctaText, description, title, handleClick }) => {
  return (
    <StepWrapper>
      <TitleAndDescriptionWrapper>
        <StepTitle>{title}</StepTitle>
        <StepDescription>{description}</StepDescription>
      </TitleAndDescriptionWrapper>
      <StepCTA text={ctaText} href={ctaLink} isLink onClick={handleClick} />
    </StepWrapper>
  );
};
