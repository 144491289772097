import React, { useMemo, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useBrand } from 'shared/hooks/useBrand';
import { Product, TranslationKey } from 'shared/constants';
import { CloseIcon } from 'shared/components/CloseIcon';
import { useTranslation } from 'next-i18next';
import { useFeature } from 'shared/hooks/useFeature';
import { FeatureToggles } from 'shared/constants/features';
import { useBrandConfig } from 'shared/hooks/useBrandConfig';
import { NavMenuLink } from 'features/NavMenu/NavMenuLink/NavMenuLink';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { FlattenedLinks, flattenNavMenu } from 'features/NavMenu/flattenNavMenu';

const duration = 150;

const NavMenuHeader = styled.h1`
  ${({ theme }) => `
    grid-area: header;
    position: relative;
    height: ${theme.components.navMenu.header.height};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.components.navMenu.header.padding};
    border-bottom: ${theme.components.navMenu.header.borderBottom};
    background-color: ${theme.components.navMenu.header.backgroundColor};
    font: ${theme.components.navMenu.header.font};
  `}
`;

const Logo = styled.span`
  ${({ theme }) => `
    display: flex;

    a {
      display: flex;
    }

    img {
      width: ${theme.layout.pageHeader.logo.width.mobile};
      margin: ${theme.layout.pageHeader.logo.margin};
    }
    margin: ${theme.components.navMenu.logo.margin};
  `}
`;

const CloseButton = styled.button`
  ${({ theme }) => `
    display: flex;
    border: none;
    background: none;
    padding: 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: ${theme.components.navMenu.closeButton.borderRadius};
    margin: ${theme.components.navMenu.closeButton.margin};
    width: ${theme.components.navMenu.closeButton.width};
    height: ${theme.components.navMenu.closeButton.height};

    &:hover {
      background-color: ${theme.components.navMenu.closeButton.hover.backgroundColor};
    }
  `}
`;

const BackButton = styled.button`
  ${({ theme }) => `
    display: flex;
    border: none;
    background: none;
    padding: 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    border-radius: ${theme.components.navMenu.backButton.borderRadius};
    padding: ${theme.components.navMenu.backButton.padding};
    margin: ${theme.components.navMenu.backButton.margin};

    &:hover {
      background-color: ${theme.components.navMenu.backButton.hover.backgroundColor};
    }
  `}
`;

const BackButtonIcon = styled.svg<{ src: string }>`
  ${({ theme, src }) => `
    width: ${theme.components.navMenu.backButton.icon.width};
    height: ${theme.components.navMenu.backButton.icon.height};
    background-color: ${theme.components.navMenu.backButton.icon.backgroundColor};
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    mask-image: url(${src});
  `}
`;

const NavMenuWrapper = styled.nav`
  display: grid;
  grid-template-areas: 'header' 'links';
  grid-template-rows: auto auto;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  overflow: hidden;
`;

const transitionName = 'slide';

const LinksWrapper = styled.ul<{ duration: number; isForwardClick: boolean; isAtStart: boolean }>`
  ${({ duration, isAtStart, isForwardClick, theme }) => {
    return `
      grid-area: links;
      list-style-type: none;
      margin: 0;
      padding: ${theme.components.navMenu.linksWrapper.padding};
      position: relative;

      ${
        isAtStart
          ? `
          padding: ${theme.components.navMenu.linksWrapper.atStart.padding};

          &:after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            margin: 10px 21px;
            border-bottom: ${theme.components.navMenu.linksWrapper.atStart.borderBottom};
          }
      `
          : ''
      }

      &.${transitionName}-enter {
        transform: translateX(${isForwardClick ? '100%' : '-100%'});
      }

      &.${transitionName}-enter-active {
        transform: translateX(0%);
        transition: transform ${duration}ms ease-in-out;
      }
      &.${transitionName}-enter-done {
        transform: translateX(0%);
      }

      &.${transitionName}-exit {
        transform: none;
      }

      &.${transitionName}-exit-active {
        transition: transform ${duration}ms ease-in-out;
        transform: translateX(${isForwardClick ? '-100%' : '100%'});
      }
    `;
  }}
`;

interface Props {
  onClose: () => void;
}

export interface MenuLink {
  title: string;
  links: MenuLink[];
  url: string;
  openInCurrentTab?: boolean;
}

export const NavMenu: React.FC<Props> = ({ onClose }) => {
  const brand = useBrand();
  const theme = useTheme();
  const nodeRef = useRef(null);
  const brandConfig = useBrandConfig()[Product.ALL];
  const rawNavLinks = brandConfig.navMenuLinks;

  const { t: translate } = useTranslation(`${brand}.${TranslationKey.COMMON}`);

  const flattenedLinks = useMemo(() => {
    return flattenNavMenu(rawNavLinks);
  }, [rawNavLinks]);

  const [navState, setNavState] = useState<{ isForwardClick: boolean; currentNavLinkId: string }>();
  const { isForwardClick, currentNavLinkId } = navState || {};
  const currentLink = currentNavLinkId ? flattenedLinks[currentNavLinkId] : { title: '', links: [], parentId: '' };
  const shouldShowHeaderLogo = useFeature(FeatureToggles.FEATURE_PAGE_HEADER_LOGO, brand);
  const currentLinks =
    currentLink.links && currentNavLinkId
      ? currentLink.links.map((link) => flattenedLinks[link])
      : Object.values(flattenedLinks).filter((link) => !link.parentId) || [];
  const isAtStart = currentLinks.some((link) => !link.parentId);
  const handleBackButtonClick = () => {
    setNavState({ isForwardClick: false, currentNavLinkId: currentLink.parentId });
  };

  const handleForwardLinkClick = (id) => {
    setNavState({ isForwardClick: true, currentNavLinkId: id });
  };

  return (
    <NavMenuWrapper>
      <NavMenuHeader>
        {currentNavLinkId ? (
          <>
            <BackButton aria-label="nav-menu-back-button" onClick={handleBackButtonClick}>
              <BackButtonIcon src={`/assets/${brand}/Icons/large_chevron_left.svg`} />
            </BackButton>
            {translate(currentLink.title)}
          </>
        ) : (
          <Logo data-test="logo" tabIndex={0}>
            <a href={translate('header.logo.href')} target="_blank" rel="noopener noreferrer" tabIndex={-1}>
              {shouldShowHeaderLogo && (
                <img alt={translate('header.logo.alt')} src={theme.layout.pageHeader.logo.imageSrc} />
              )}
            </a>
          </Logo>
        )}

        <CloseButton data-test="close-button" aria-label="header.close.button" type="button" onClick={onClose}>
          <CloseIcon src={`/assets/${brand}/Icons/close.svg`} />
        </CloseButton>
      </NavMenuHeader>

      <TransitionGroup
        component={null}
        childFactory={(child) => {
          return React.cloneElement(child, {
            isForwardClick: Boolean(isForwardClick),
          });
        }}
      >
        <CSSTransition key={currentNavLinkId} classNames={transitionName} timeout={duration}>
          <LinksWrapper
            ref={nodeRef}
            duration={duration}
            isForwardClick={Boolean(isForwardClick)}
            isAtStart={isAtStart}
          >
            {currentLinks.map((link, index) => {
              return link.url ? (
                <NavMenuLink text={link.title} link={link.url} key={link.id} />
              ) : (
                <NavMenuLink text={link.title} key={link.id} onClick={() => handleForwardLinkClick(link.id)} />
              );
            })}
          </LinksWrapper>
        </CSSTransition>
      </TransitionGroup>
    </NavMenuWrapper>
  );
};
